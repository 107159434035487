.modal {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", sans-serif;
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__container {
  background-color: #fff;
  max-height: 100vh;
  border-radius: 4px;
  overflow-y: auto;
  box-sizing: border-box;
  display: flex;
}

.modal__container.preset-form {
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 24%);
}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal__title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25;
  color: #00449e;
  box-sizing: border-box;
}

.modal__close {
  cursor: pointer;
  background: transparent;
  border: 0;
  top: 10px;
  right: 10px;
  position: absolute;
}

.modal__close:before {
  content: "\2715";
  font-size: 18px;
}

.modal__content {
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.8);
}

.modal__btn {
  font-size: 0.875rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #e6e6e6;
  color: rgba(0, 0, 0, 0.8);
  border-radius: 0.25rem;
  border-style: none;
  border-width: 0;
  cursor: pointer;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  line-height: 1.15;
  margin: 0;
  will-change: transform;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.modal__btn:focus,
.modal__btn:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.modal__btn-primary {
  background-color: #00449e;
  color: #fff;
}

/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide.shown {
  display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}

/* .modal__container{
position: fixed;
top: 20px;
left: 20px;
}

.modal__overlay{
background: none;
} */

div.form-step {
  display: none;
}
div.form-step.current {
  display: block;
}

.full-width {
  width: 100%;
}
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.ql-align-center,
.align-center {
  text-align: center;
}

.content {
  width: 100%;
}

.content.preset {
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, arial, sans-serif !important;
}

.content.preset .icon {
  height: 60px;
}
.content.preset .icon img {
  height: 60px;
  width: 60px;
}

.content.preset button.button {
  padding: 15px 30px;
  font-size: 15px;
}

.content.preset button.button:hover {
  opacity: 0.7;
  font-size: 110%;
}

.content.preset .footer {
  font-size: 13px;
}

.content.preset .title-text {
  font-size: 20px;
  font-weight: 500;
}

.modal__container.background-left {
  flex-direction: row-reverse;
}

.modal__container .background {
  width: 50%;
}

.modal__container .background-entire {
  width: 100%;
}

input {
  height: 50px;
  font-size: 19px;
  margin-bottom: 10px !important;
  position: relative;
  padding: 0.5rem 1.5rem;
  background-color: #fff;
  border: 0.1rem solid #c4cdd5;
  border-radius: 4px;
  color: #31373d;
  display: block;
  width: 100%;
  line-height: 2.4rem;
  min-width: 7.5rem;
  max-width: 100%;
  box-shadow: 0 0 0 1px #0000, 0 1px #3a2c2c0d;
  font-family: inherit;
}

.button {
  font-family: inherit;
  position: relative;
  display: inline-block;
  border: 0.1rem solid #fff0;
  border-radius: 0.3rem;
  line-height: normal;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  text-transform: none;
  font-weight: normal;
  vertical-align: middle;
  user-select: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: #0000;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  overflow: hidden;
  background-color: #000;
  color: #fff;
}

.form-section {
  padding: 0px 20px 20px 20px;
}
.form-section:first-of-type {
  padding: 20px;
}
.form-section:last-of-type {
  padding: 20px 20px 40px 20px;
}

a {
  outline: none;
  text-decoration: none;
}

a:focus {
  box-shadow: 0 0 0 0.1rem rgba(87, 85, 217, 0.2);
}

a:focus,
a:hover,
a:active,
a.active {
  text-decoration: underline;
}

.ql-size-huge {
  font-size: 2.5em;
}

.modal__overlay.top_right.overlay-enabled {
  padding: 20px;
  align-items: flex-start;
  -webkit-box-pack: end;
  justify-content: flex-end;
}

.modal__overlay.overlay-disabled {
  background: none;
}
